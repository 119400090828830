.container {
    display: flex;
    justify-content: center;
    width: 85%;
}

@media (max-width: 770px){
    .container{
        width: 100%;
    }
}