body {
    background-color: rgb(250, 250, 250);
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image {
    width: 300px;
    margin-top: 20px;
}

@media (max-width: 770px){
    .container{
        margin: 0 10%;
    }

    .image{
        width: 100%;
    }
}